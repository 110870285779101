<template>
  <div class="boss">
    <p class="proxyLogin-title">第三方登陆</p>
    <div class="container">
      <img src="~@/assets/icon/weChat_xu.png"/>
      <div class="container2">
        <span>微信(手机号)</span>
        <span>未绑定</span>
      </div>
      <el-button class="button" type="primary">绑定</el-button>
    </div>
    <div class="container">
      <img src="~@/assets/icon/weibo_xu.png"/>
      <div class="container2">
        <span>新浪微博</span>
        <span>未绑定</span>
      </div>
      <el-button class="button" type="primary">绑定</el-button>
    </div>
    <div class="container">
      <img src="~@/assets/icon/QQ_xu.png"/>
      <div class="container2">
        <span>QQ</span>
        <span>未绑定</span>
      </div>
      <el-button class="button" type="primary">绑定</el-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.proxyLogin-title{
  font-size: 0.102941rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(3,3,3,1);
  /* line-height: 58px; */
  font-weight: 800;
  padding-bottom: 0.110294rem;
  border-bottom: 2px solid #B5B5B5;
}
.container{
  padding-left: 10px;
  width: 50%;
  margin-bottom: 50px;
}
.container img{
  vertical-align: top;
}
.container2{
  display: inline-block;
  vertical-align: top;
}
.container2 span{
  display: block;
  padding-left: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #7D7D7D;
}
.button{
  float: right;
}
.container::after{
  display: block;
  content: "";
  clear: both;
}
</style>
